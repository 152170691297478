/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import Cookies from "./cookies"
//import "./base.css"
//import "./bootstrap.min.css"
import "./addon.min.css"
import "./theme.css"
import "./vf-addon.css"

import "@fortawesome/fontawesome-pro/css/all.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="main-app-wrap">
      <Helmet>
        <script src={withPrefix("script.js")} type="text/javascript" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title}></Header>
      <div className="app-layout">
        <main id="main">{children}</main>
      </div>
      <div className="footer-layout">
        <Footer />
        <Cookies />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
