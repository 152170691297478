import React, { useState } from "react"

import { Link } from "gatsby"

const Header = props => {
  const [showMobileNav, setShowMobileNav] = useState(false)

  function toogleMobileNav() {
    if (showMobileNav) {
      setShowMobileNav(false)
    } else {
      setShowMobileNav(true)
    }
  }

  return (
    <div className="top-header">
      <div className="header-row">
        <div className="logo-wrapper">
          <div className="logo">
            <Link to="/">
              <img
                width="118"
                height="94"
                src="https://cdn1.accropress.com/devonshire-logo.svg"
              />
            </Link>
          </div>
        </div>
        <div className="nav-wrapper">
          <div className="nav desktop">
            <ul>
              <li className="reciteMe-btn">
                <button
                  onClick={() => window.loadService()}
                  className="button btn btn-primary-inverted btn-small"
                >
                  Accessibility Tools
                </button>
              </li>
              <li>
                <Link to="/devonshire-group/">The Devonshire Group</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/derbyshire-estate/">
                      The Derbyshire Estate <span>Chatsworth</span>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/derbyshire-estate/vacancies">Vacancies</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/yorkshire-estate/">
                      The Yorkshire Estate <span>Bolton Abbey</span>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/yorkshire-estate/vacancies">Vacancies</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/irish-estate/">
                      The Irish Estate <span>Lismore &amp; Careysville</span>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/irish-estate/vacancies">Vacancies</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/hotels-and-restaurants/">
                      Devonshire Hotels &amp; Restaurants{" "}
                      <span>The Derbyshire &amp; Yorkshire Estates</span>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/hotels-and-restaurants/vacancies">
                          Vacancies
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/devonshire-group/register-your-interest/">
                  Register Your Interest
                </Link>
              </li>
              <li>
                <Link to="/devonshire-group/volunteering/">Volunteering</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/devonshire-group/volunteering/vacancies">
                      Vacancies
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/devonshire-group/work-experience/">
                  Apprenticeships, Work Experience and Placements
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/devonshire-group/work-experience/vacancies">
                      Vacancies
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/devonshire-group/working-with-us/">
                  Working with us
                </Link>
              </li>
            </ul>
          </div>

          <div className="nav-mobile">
            <input
              type="checkbox"
              className="openSidebarMenu"
              id="openSidebarMenu"
            />
            <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
              <div className="spinner diagonal part-1"></div>
              <div className="spinner horizontal"></div>
              <div className="spinner diagonal part-2"></div>
            </label>
            <div id="sidebarMenu">
              <ul className="sidebarMenuInner">
                <li>
                  <Link to="/devonshire-group/">The Devonshire Group</Link>
                  <ul>
                    <li>
                      <Link to="/derbyshire-estate/">
                        The Derbyshire Estate <span>Chatsworth</span>
                      </Link>
                      <ul>
                        <li>
                          <Link to="/derbyshire-estate/vacancies">
                            Vacancies
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/yorkshire-estate/">
                        The Yorkshire Estate <span>Bolton Abbey</span>
                      </Link>
                      <ul>
                        <li>
                          <Link to="/yorkshire-estate/vacancies">
                            Vacancies
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/irish-estate/">
                        The Irish Estate <span>Lismore &amp; Careysville</span>
                      </Link>
                      <ul>
                        <li>
                          <Link to="/irish-estate/vacancies">Vacancies</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/hotels-and-restaurants/">
                        Devonshire Hotels &amp; Restaurants{" "}
                        <span>The Derbyshire &amp; Yorkshire Estates</span>
                      </Link>
                      <ul>
                        <li>
                          <Link to="/hotels-and-restaurants/vacancies">
                            Vacancies
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/devonshire-group/register-your-interest/">
                    Register Your Interest
                  </Link>
                </li>
                <li>
                  <Link to="/devonshire-group/volunteering/">Volunteering</Link>
                </li>
                <li>
                  <Link to="/devonshire-group/work-experience/">
                    Apprenticeships, Work Experience and Placements
                  </Link>
                </li>
                <li>
                  <Link to="/devonshire-group/working-with-us/">
                    Working with us
                  </Link>
                </li>
                <li className="reciteMe-btn">
                  <button
                    onClick={() => window.loadService()}
                    className="button btn btn-primary-inverted btn-small"
                  >
                    Accessibility Tools
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
